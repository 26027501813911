<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      Pay: "Pagar",
      "Enter the order number": "Digite o número do pedido",
      "This order is not available for payment or already paid.":
        "Este pedido não está disponível para pagamento ou já foi pago.",
      "You have no balance to pay for this order.":
        "Você não possui saldo para pagar este pedido.",
      "Your payment was successful.": "Pagamento realizado com sucesso!",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",

      "Security Password": "Senha de Segurança",
      Total: "Total to pedido",
      Order: "Pedido",
      "Pay with balance": "Pagar com saldo",

      "Form of payment": "Forma de pagamento",
      "Account balance": "Saldo em conta",
      Value: "Valor",
      Tax: "Taxa",
      "Your balance will be": "O seu saldo será de",
      "after payment": "após o pagamento",
      Date: "Data",
      From: "De",
    },
    es: {
      Pay: "Pagar",
      "Enter the order number": "Introduzca el número de pedido",
      "This order is not available for payment or already paid.":
        "Este pedido no está disponible para pago o ya está pagado.",
      "You have no balance to pay for this order.":
        "Usted no tiene saldo a pagar por este pedido.",
      "Your payment was successful.": "Pagamento realizado com sucesso!",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",

      "Security Password": "Senha de Segurança",
      Total: "Total to pedido",
      Order: "Pedido",
      "Pay with balance": "Pagar com saldo",

      "Form of payment": "Forma de pagamento",
      "Account balance": "Saldo em conta",
      Value: "Valor",
      Tax: "Taxa",
      "Your balance will be": "O seu saldo será de",
      "after payment": "após o pagamento",
      Date: "Data",
      From: "De",
    },
  },
  components: { Layout, Password },
  data() {
    return {
      search: {
        loading: false,
        order: null,
      },

      user: {
        password: "",
        name: null,
        avatar: null,
        credit: null,
      },

      credit: null,

      order: {
        modal: false,
        loading: false,

        id: null,
        voucher: false,
        date: null,

        subtotal: 0,
        discount: 0,
        total: 0,
        tax: "",
        credit: 0,

        items: null,
        status: null,

        payment: {
          name: "Saldo em Conta",
          status: "pending",
          total: null,
          address: null,
          qrcode: null,
          expiration: null,
        },
      },

      alert: { type: "", message: "" },
    };
  },
  validations: {
    order: { required },
    user: {
      password: { required },
    },
  },
  methods: {
    searchOrder() {
      this.$v.$touch();

      if (this.search.order) {
        this.search.loading = true;

        api
          .post("wallet/pay/search", {
            order: this.search.order,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.search.order = "";

              this.$v.$reset();
              this.order.id = response.data.order.id_order;
              this.order.total = response.data.order.total;
              this.order.date = response.data.order.date;
              this.user.name = response.data.order.name;
              this.user.avatar = response.data.order.avatar;
              this.user.credit = response.data.order.credit;
              this.order.tax = response.data.order.tax;
              this.order.modal = true;
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }

            this.search.loading = false;
          });
      }
    },
    payOrder(id) {
      this.order.loading = true;

      api
        .post("wallet/pay", {
          order: id,
          password: this.user.password,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(this.t(response.data.message));
            this.order.modal = false;
          } else {
            this.$noty.error(this.t(response.data.message));
          }

          this.order.loading = false;
        });
    },
    getOrder() {
      if (this.$route.params.id) {
        this.search.order = this.$route.params.id;
        this.searchOrder();
        this.$router.replace("/wallet/pay");
      }
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <div>
            <h6 class="m-0 text-uppercase font-size-12">Carteira de Rede</h6>
            <h4 class="mb-0 font-size-18">{{ t("Pay") }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="searchOrder">
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form-group
                id="order"
                :label="t('Enter the order number')"
                label-for="order"
              >
                <b-form-input
                  id="order"
                  v-model="search.order"
                  type="text"
                  :class="{ 'is-invalid': $v.order.$error }"
                ></b-form-input>
                <div v-if="$v.order.$error" class="invalid-feedback">
                  <span v-if="!$v.order.required">{{
                    t("Order is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="search.loading == true || this.search.order === ''"
                  type="submit"
                  variant="default"
                >
                  {{ t("Pay") }}
                  <b-spinner
                    v-if="search.loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
        <b-modal
          v-model="order.modal"
          hide-footer
          centered
          header-class="border-0"
          body-class="px-3"
        >
          <b-alert
            show
            variant="warning"
            class="blockquote position-relative border-0 rounded-0"
          >
            <div class="text-center my-3">
              <div class="text-dark text-center font-weight-bold mt-3">
                <strong class="text-uppercase font-weight-normal">{{
                  t("Order")
                }}</strong>
                - {{ order.id }}
              </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <p class="m-0 text-uppercase">{{ t("Date") }}:</p>
              <p class="m-0">{{ order.date }}</p>
            </div>
            <hr class="mt-0" style="border: 1px dashed" />
            <div class="d-flex align-items-center">
              <img
                v-if="user.avatar"
                class="rounded-circle header-profile-user m-2 mx-2"
                width="30px"
                height="30px"
                :src="user.avatar"
                alt=""
              />
              <img
                v-else
                class="rounded-circle header-profile-user"
                width="30px"
                height="30px"
                :src="
                  'https://ui-avatars.com/api/?background=2c0f3f&color=fff&name=' +
                  user.name
                "
                alt=""
              />
              <figcaption class="font-weight-bold">
                {{ t("From") }}:<br />
                <strong>{{ user.name }}</strong>
              </figcaption>
            </div>
            <hr class="mb-1" />
            <div class="d-flex justify-content-between">
              <p class="m-0">{{ t("Form of payment") }}:</p>
              <p class="m-0 text-right">{{ t("Account balance") }}</p>
            </div>
            <hr class="m-1" />
            <div class="d-flex justify-content-between">
              <p class="m-0">{{ t("Value") }}:</p>
              <p class="m-0">
                <b class="font-weight-normal">{{ order.total | currency }}</b>
              </p>
            </div>
            <div>
              <hr class="m-1" />
              <div class="d-flex justify-content-between">
                <p class="m-0">{{ t("Tax") }}:</p>
                <p class="m-0">
                  <b class="font-weight-normal">{{ order.tax | currency }}</b>
                </p>
              </div>
              <small v-if="order.tax > 0" class="d-block text-center mt-4"
                ><b>Atenção!</b> É adicionado a taxa de
                {{ order.tax | currency }} para pagamentos com saldo.</small
              >
            </div>
            <b-form
              class="mt-2 mb-3 text-center"
              @submit.prevent="payOrder(order.id)"
            >
              <small
                >{{ t("Your balance will be") }} {{ user.credit | currency }}
                {{ t("after payment") }}</small
              >
              <b-form-group :label="t('Security Password')">
                <Password
                  v-model="user.password"
                  :v="$v.user.password"
                ></Password>
              </b-form-group>
              <b-button
                class="w-100"
                v-b-modal.modal-1
                :disabled="order.loading == true || this.user.password === ''"
                type="submit"
                variant="white"
              >
                {{ t("Pay") }}
                <b-spinner
                  v-if="order.loading"
                  small
                  class="ml-2 align-middle"
                  variant="white"
                  role="status"
                ></b-spinner>
              </b-button>
            </b-form>
          </b-alert>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
.blockquote {
  font-size: 0.9rem;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}
.blockquote:after,
.blockquote:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  background-repeat: repeat-x !important;
  background-size: 25px 20px, 25px 20px !important;
  background-position: center !important;
}
.blockquote:after {
  background: linear-gradient(-45deg, transparent 70%, #fcf0db 70%),
    linear-gradient(45deg, transparent 70%, #fcf0db 70%);
  height: 29px;
  bottom: -24px;
}
.blockquote:before {
  background: linear-gradient(125deg, transparent 70%, #fcf0db 70%),
    linear-gradient(-126deg, transparent 70%, #fcf0db 70%);
  height: 29px;
  top: -24px;
}
</style>
